<template>
  <div class="background">
    <div class="setting-btn">
      <el-button icon="el-icon-s-tools" type="primary" circle @click="settingVisible = true" />
    </div>
    <div class="lottery-cName">
      <img src="@/assets/images/lottery-name.png" class="lottery-cName-img" />
    </div>
    <div class="lottery-title">
      <img src="@/assets/images/lottery-title.png" class="lottery-title-img" />
    </div>
    <div class="lottery-container">
      <div class="lottery">
        <vue-seamless-scroll v-show="!visible" class="seamless-warp" :data="list" :class-option="classOption()">
          <ul>
            <li v-for="(item, index) in list" :key="index">{{ item.name }}</li>
          </ul>
        </vue-seamless-scroll>

        <div v-show="visible" class="xianshi">
          <ul class="nostyle">
            <li class="nostyle">{{ label }}</li>
          </ul>
        </div>
      </div>
    </div>

    <div class="lottery-btn">
      <img src="@/assets/images/lottery-btn.png" class="btn-img" @click="startLottery"/>
      <img src="@/assets/images/lottery-btn.png" class="btn-img" @click="drawWinner"/>
      <div class="btn-box" @click="startLottery">
        <div class="btn-text-box">
          <span>开始抽奖</span>
        </div>
      </div>
      <div class="btn-box" @click="drawWinner">
        <div class="btn-text-box">
          <span>停止滚动</span>
        </div>
      </div>

      <!-- 左侧喷花 -->
      <celebrate 
        :options="{loop: false, autoplay: false, type: 'default'}"
        :speed="0.5"
        :control="visible"
        class="celebrate-box celebrate-left"
      />
      <celebrate 
        :options="{loop: false, autoplay: false}"
        :control="visible"
        class="celebrate-box celebrate-left"
      />
      <!-- 中间喷花 -->
      <celebrate 
        :options="{loop: false, autoplay: false, type: 'default'}"
        :speed="0.5"
        :control="visible"
        class="celebrate-box celebrate-center"
      />
      <celebrate 
        :options="{loop: false, autoplay: false}"
        :control="visible"
        class="celebrate-box celebrate-center"
      />
      <!-- 右侧喷花 -->
      <celebrate 
        :options="{loop: false, autoplay: false, type: 'default'}"
        :speed="0.5"
        :control="visible"
        class="celebrate-box celebrate-right"
      />
      <celebrate 
        :options="{loop: false, autoplay: false}"
        :control="visible"
        class="celebrate-box celebrate-right"
      />
    </div>

    <div class="winner-list">
      <div class="winner-list-title">中奖名单</div>

      <el-form ref="form" label-width="120px">
        <el-form-item v-if="winnerList.first.personArr.length !== 0" style="margin-bottom: 10px;">
          <span slot="label" class="winner-list-label">一等奖</span>

          <div class="winner-list-box">
            <div v-for="(item, index) in winnerList.first.personArr" :key="index" class="winner-list-item">
              <span class="winner-list-item-text">{{ item.value }}</span>
            </div>
          </div>
        </el-form-item>

        <el-form-item v-if="winnerList.second.personArr.length !== 0" style="margin-bottom: 10px;">
          <span slot="label" class="winner-list-label">二等奖</span>

          <div class="winner-list-box">
            <div v-for="(item, index) in winnerList.second.personArr" :key="index" class="winner-list-item">
              <span class="winner-list-item-text">{{ item.value }}</span>
            </div>
          </div>
        </el-form-item>

        <el-form-item v-if="winnerList.third.personArr.length !== 0" style="margin-bottom: 10px;">
          <span slot="label" class="winner-list-label">三等奖</span>

          <div class="winner-list-box">
            <div v-for="(item, index) in winnerList.third.personArr" :key="index" class="winner-list-item">
              <span class="winner-list-item-text">{{ item.value }}</span>
            </div>
          </div>
        </el-form-item>

        <el-form-item v-if="winnerList.fourth.personArr.length !== 0" style="margin-bottom: 10px;">
          <span slot="label" class="winner-list-label">四等奖</span>

          <div class="winner-list-box">
            <div v-for="(item, index) in winnerList.fourth.personArr" :key="index" class="winner-list-item">
              <span class="winner-list-item-text">{{ item.value }}</span>
            </div>
          </div>
        </el-form-item>

        <el-form-item v-if="winnerList.fifth.personArr.length !== 0" style="margin-bottom: 10px;">
          <span slot="label" class="winner-list-label">五等奖</span>

          <div class="winner-list-box">
            <div v-for="(item, index) in winnerList.fifth.personArr" :key="index" class="winner-list-item">
              <span class="winner-list-item-text">{{ item.value }}</span>
            </div>
          </div>
        </el-form-item>

        <el-form-item v-if="winnerList.special.personArr.length !== 0" style="margin-bottom: 10px;">
          <span slot="label" class="winner-list-label">特等奖</span>

          <div class="winner-list-box">
            <div v-for="(item, index) in winnerList.special.personArr" :key="index" class="winner-list-item">
              <span class="winner-list-item-text">{{ item.value }}</span>
            </div>
          </div>
        </el-form-item>
      </el-form>
    </div>

    <!-- 设置表单 -->
    <el-drawer title="设置" size="600px" :visible.sync="settingVisible" :wrapperClosable="false" @close="settingVisible = false">
      <div class="setting-box">
        <el-form ref="settingForm" label-width="100px">
          <el-form-item label="一等奖设置">
            <el-input-number v-model="winnerList.first.limit" :min="0" placeholder="请输入一等奖数量" />

            <el-input v-model="manualAdd.first" placeholder="请输入添加人">
              <el-button slot="append" type="primary" icon="el-icon-plus" @click="manualAddPerson(1, manualAdd.first)" />
            </el-input>

            <div v-if="winnerList.first.personArr.length !== 0" class="setting-winner-list">
              <div v-for="(item, index) in winnerList.first.personArr" :key="index" class="setting-winner-list-item">
                <el-tag type="warning" closable @close="cancelPerson(1, item.value)">{{ item.value }}</el-tag>
              </div>
            </div>
          </el-form-item>

          <el-form-item label="二等奖设置">
            <el-input-number v-model="winnerList.second.limit" :min="0" placeholder="请输入二等奖数量" />

            <el-input v-model="manualAdd.second" placeholder="请输入添加人">
              <el-button slot="append" type="primary" icon="el-icon-plus" @click="manualAddPerson(2, manualAdd.second)" />
            </el-input>

            <div v-if="winnerList.second.personArr.length !== 0" class="setting-winner-list">
              <div v-for="(item, index) in winnerList.second.personArr" :key="index" class="setting-winner-list-item">
                <el-tag type="warning" closable @close="cancelPerson(2, item.value)">{{ item.value }}</el-tag>
              </div>
            </div>
          </el-form-item>

          <el-form-item label="三等奖设置">
            <el-input-number v-model="winnerList.third.limit" :min="0" placeholder="请输入三等奖数量" />

            <el-input v-model="manualAdd.third" placeholder="请输入添加人">
              <el-button slot="append" type="primary" icon="el-icon-plus" @click="manualAddPerson(3, manualAdd.third)" />
            </el-input>

            <div v-if="winnerList.third.personArr.length !== 0" class="setting-winner-list">
              <div v-for="(item, index) in winnerList.third.personArr" :key="index" class="setting-winner-list-item">
                <el-tag type="warning" closable @close="cancelPerson(3, item.value)">{{ item.value }}</el-tag>
              </div>
            </div>
          </el-form-item>

          <el-form-item label="四等奖设置">
            <el-input-number v-model="winnerList.fourth.limit" :min="0" placeholder="请输入四等奖数量" />

            <el-input v-model="manualAdd.fourth" placeholder="请输入添加人">
              <el-button slot="append" type="primary" icon="el-icon-plus" @click="manualAddPerson(4, manualAdd.fourth)" />
            </el-input>

            <div v-if="winnerList.fourth.personArr.length !== 0" class="setting-winner-list">
              <div v-for="(item, index) in winnerList.fourth.personArr" :key="index" class="setting-winner-list-item">
                <el-tag type="warning" closable @close="cancelPerson(4, item.value)">{{ item.value }}</el-tag>
              </div>
            </div>
          </el-form-item>

          <el-form-item label="五等奖设置">
            <el-input-number v-model="winnerList.fifth.limit" :min="0" placeholder="请输入五等奖数量" />

            <el-input v-model="manualAdd.fifth" placeholder="请输入添加人">
              <el-button slot="append" type="primary" icon="el-icon-plus" @click="manualAddPerson(5, manualAdd.fifth)" />
            </el-input>

            <div v-if="winnerList.fifth.personArr.length !== 0" class="setting-winner-list">
              <div v-for="(item, index) in winnerList.fifth.personArr" :key="index" class="setting-winner-list-item">
                <el-tag type="warning" closable @close="cancelPerson(5, item.value)">{{ item.value }}</el-tag>
              </div>
            </div>
          </el-form-item>

          <el-form-item label="特等奖设置">
            <el-input v-model="manualAdd.special" placeholder="请输入添加人">
              <el-button slot="append" type="primary" icon="el-icon-plus" @click="manualAddPerson(0, manualAdd.special)" />
            </el-input>

            <div v-if="winnerList.special.personArr.length !== 0" class="setting-winner-list">
              <div v-for="(item, index) in winnerList.special.personArr" :key="index" class="setting-winner-list-item">
                <el-tag type="warning" closable @close="cancelPerson(0, item.value)">{{ item.value }}</el-tag>
              </div>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import vueSeamlessScroll from 'vue-seamless-scroll'
import celebrate from '@/components/celebrate'
import cheersMp3 from '@/assets/audio/other/cheers.mp3'

export default {
  name: 'Lottery',
  components: { vueSeamlessScroll, celebrate },
  data() {
    return {
      // 人员名单
      list: [
        { name: '齐勇峰' },
        { name: '李恩芳' },
        { name: '包林浩' },
        { name: '刘国杰' },
        { name: '赵士淞' },
        { name: '张博睿' },
        { name: '赵利浩' },
        { name: '聂璐宁' },
        { name: '卢新月' },
        { name: '孙琦' },
        { name: '张鹏' },
        { name: '姚增春' },
        { name: '张骁勇' },
        { name: '张宁' },
        { name: '佟占利' },
        { name: '吴倩' },
        { name: '穆政道' },
        { name: '蔡西红' },
        { name: '崔皓然' },
        { name: '郭颖' },
        { name: '田林林' },
        // { name: '陆海晴' },
        { name: '魏传栋' },
        { name: '韩林静' },
        { name: '康琳' },
        { name: '杨梦佳' },
        { name: '杨心如' },
        { name: '李映璇' },
        { name: '姚建阳' },
        { name: '代纪龙' },
        { name: '吴勇' },
        { name: '郭晓蕊' },
        { name: '王鑫艳' },
        { name: '王婧' },
        { name: '韩照然' },
        { name: '刘文文' },
        { name: '何亚南' },
        { name: '吴高恒' },
        { name: '李博' },
        // { name: '刘新雨' },
        { name: '常媛媛' },
      ],
      // 手动添加
      manualAdd: {
        first: null,
        second: null,
        third: null,
        fourth: null,
        fifth: null,
        special: null
      },
      // 抽中名单
      winnerList: {
        first: {
          limit: 1,
          personArr: []
        },
        second: {
          limit: 2,
          personArr: []
        },
        third: {
          limit: 3,
          personArr: []
        },
        fourth: {
          limit: 4,
          personArr: []
        },
        fifth: {
          limit: 5,
          personArr: []
        },
        special: {
          personArr: []
        }
      },
      // 抽中者名
      label: '',
      // 是否滚动
      visible: false,
      // 滚动速度
      step: 1,
      // 抽奖loading
      loading: false,
      // 长时间未操作恢复滚动
      timeoutFunc: null,
      // 设置显示判断
      settingVisible: false,
      cheersMusic: new Audio(cheersMp3),
    }
  },

  methods: {
    classOption() {
      return {
        step: this.step,
        hoverStop: false,
        direction: 0,
      }
    },

    // 抽奖点击事件
    startLottery() {
      const that = this

      if (!that.loading) {
        if(that.list.length > 0) {
          clearTimeout(that.timeoutFunc)
          that.loading = true
          that.visible = false
          that.step = 8
        } else {
          that.$message({ message: '没有抽奖人员了~', type: 'warning' });
        }
      } else {
        that.$message({ message: '抽奖中。。。', type: 'warning' });
      }
    },

    // 抽出幸运者
    drawWinner() {
      const that = this

      if (that.loading) {
        // 逐步减速
        setTimeout(() => {
          that.step = 6
        }, 1000)
        setTimeout(() => {
          that.step = 4
        }, 2000)
        setTimeout(() => {
          that.step = 2
        }, 3000)
        setTimeout(() => {
          that.step = 0

          try {
            // 计算中奖者位置下标
            const sw = document.getElementsByClassName('seamless-warp')
            const transform = sw[0].children[0].style.transform
            const singleItemHeight = (sw[0].children[0].children[0].children[0].children[0].getBoundingClientRect().height).toFixed(2)
            const topNum = Number(transform.replace(/[^0-9.]/ig, ""))
            let offset = (topNum / singleItemHeight).toFixed(0)

            // 复位至获奖者显示位置
            const resetOffset = offset * singleItemHeight
            // 切分段数
            const cutNum = 20
            const offsetGap = Number(((resetOffset - topNum) / cutNum).toFixed(2))
            // 偏差逐步复位（0.5秒）
            for (let i = 1; i <= cutNum; i++) {
              setTimeout(() => {
                sw[0].children[0].style.transform = `translate(0px, -${topNum + i * offsetGap}px)`

                // 最终复位
                if (i === cutNum) {
                  // 计算名单位置
                  that.visible = true
                  offset = offset < that.list.length ? offset : (offset % that.list.length)
                  sw[0].children[0].style.transform = `translate(0px, -${resetOffset}px)`
                  that.label = that.list[offset].name
                  console.log('-------中奖人员:' + that.list[offset].name + '-------')
                  that.list.splice(offset, 1)

                  // 加入五等奖
                  if (that.winnerList.fifth.personArr.length < that.winnerList.fifth.limit) {
                    that.winnerList.fifth.personArr.push({value: that.label})
                  }
                  // 加入四等奖
                  else if (that.winnerList.fourth.personArr.length < that.winnerList.fourth.limit) {
                    that.winnerList.fourth.personArr.push({value: that.label})
                  }
                  // 加入三等奖
                  else if (that.winnerList.third.personArr.length < that.winnerList.third.limit) {
                    that.winnerList.third.personArr.push({value: that.label})
                  }
                  // 加入二等奖
                  else if (that.winnerList.second.personArr.length < that.winnerList.second.limit) {
                    that.winnerList.second.personArr.push({value: that.label})
                  }
                  // 加入一等奖
                  else if (that.winnerList.first.personArr.length < that.winnerList.first.limit) {
                    that.winnerList.first.personArr.push({value: that.label})
                  }
                  // 加入特等奖
                  else {
                    that.winnerList.special.personArr.push({value: that.label})
                  }
                  
                  that.loading = false
                  that.cheersMusic.play()
                  // 延时清除暂停滚动
                  that.timeoutFunc = setTimeout(() => {
                    that.onClickNormal()
                  }, 120000)
                }
              }, i * 25)
            }
          } catch (e) {
            that.loading = false
            that.visible = true
            that.$message({ message: '抽取异常', type: 'warning' });
          }
        }, 4000)
      } else {
        that.$message({ message: '请先开始抽奖', type: 'warning' });
      }
    },

    // 滚动轴恢复正常
    onClickNormal() {
      const that = this

      if (!that.loading) {
        that.visible = false
        that.step = 1
      }
    },

    // 同步随机数事件（旧抽奖模式）
    async makeChoice() {
      await this.randomNumber()
    },

    // 随机数（旧抽奖模式）
    randomNumber() {
      const that = this

      if (that.list.length > 0) {
        const arrayBounds = that.list.length
        const randomNum = Math.floor(Math.random() * arrayBounds)
        that.label = that.list[randomNum].name
        console.log('-------中奖人员:' + that.list[randomNum].name + '-------')
        that.list.splice(randomNum, 1)
      }
    },

    // 取消奖项
    cancelPerson(type, val) {
      const that = this

      // 一等奖
      if (type === 1) {
        that.winnerList.first.personArr = that.winnerList.first.personArr.filter(item => item.value !== val)
      }
      // 二等奖
      else if (type === 2) {
        that.winnerList.second.personArr = that.winnerList.second.personArr.filter(item => item.value !== val)
      }
      // 三等奖
      else if (type === 3) {
        that.winnerList.third.personArr = that.winnerList.third.personArr.filter(item => item.value !== val)
      }
      // 四等奖
      else if (type === 4) {
        that.winnerList.fourth.personArr = that.winnerList.fourth.personArr.filter(item => item.value !== val)
      }
      // 五等奖
      else if (type === 5) {
        that.winnerList.fifth.personArr = that.winnerList.fifth.personArr.filter(item => item.value !== val)
      }
      // 特等奖
      else {
        that.winnerList.special.personArr = that.winnerList.special.personArr.filter(item => item.value !== val)
      }

      // 放回人员名单
      that.list.push({ name: val })
    },

    // 手动添加
    manualAddPerson(type, val) {
      const that = this

      const filterData = that.list.filter(item => item.name === val)
      if (filterData.length > 0) {
        that.list = that.list.filter(item => item.name !== val)

        // 一等奖
        if (type === 1) {
          that.winnerList.first.personArr.push({value: val})
        }
        // 二等奖
        else if (type === 2) {
          that.winnerList.second.personArr.push({value: val})
        }
        // 三等奖
        else if (type === 3) {
          that.winnerList.third.personArr.push({value: val})
        }
        // 四等奖
        else if (type === 4) {
          that.winnerList.fourth.personArr.push({value: val})
        }
        // 五等奖
        else if (type === 5) {
          that.winnerList.fifth.personArr.push({value: val})
        }
        // 特等奖
        else {
          that.winnerList.special.personArr.push({value: val})
        }
      }
    }
  }
}
</script>

<style scoped>
.background {
  min-height: 100vh;
  width: 100%;
  /* background-image: url('../../assets/images/lottery-bg.jpg'); */
  background-image: url('../../assets/images/background_2024.jpg');
  background-repeat: no-repeat;
  background-size: 100vw 100%;
  background-position: center top;
  margin: 0px;
  padding: 0px 0px 20px 0px;
  pointer-events: auto;
  user-select: none;
}

.setting-btn {
  float: right;
  width: 40px;
  height: 40px;
}

.lottery-cName {
  width: 100%;
  text-align: center;
  padding-top: 100px;
}

.lottery-cName-img {
  height: 230px;
  width: 892px;
}

.lottery-title {
  width: 100%;
  text-align: center;
  margin-top: 20px;
}

.lottery-title-img {
  width: 624px;
  height: 110px;
}

.lottery-container {
  width: 100%;
  height: 174px;
  background-image: url('../../assets/images/lottery-box.png');
  background-repeat:no-repeat;
  background-size: 648px 174px;
  background-position-x: center;
  background-position-y: center;
  position: relative;
}

.lottery {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 68px;
}

.xianshi {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 52px;
  color: #FED580;
}

.seamless-warp {
  width: 100%;
  height: 100%;
  text-align: center;
  overflow: hidden;
  font-size: 52px;
  color: #FED580;
}

ul,li {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

.lottery-btn {
  display: flex;
  justify-content: center;
  width: 100%;
  text-align: center;
  margin-top: 20px;
  position: relative;
  gap: 12px;
}

.btn-img {
  height: 105px;
  width: 336px;
  display: none;
}

.btn-box {
  height: 93px;
  width: 324px;
  background-image: radial-gradient(ellipse at center, #FFB152, #F8CD94);
  background-image: radial-gradient(ellipse at center, #FFB152, #F8D19A);
  border-radius: 56px;
  padding: 6px;
  box-shadow: 2px 2px 4px 1px rgba(0, 0, 0, 0.5);
}

.btn-text-box {
  height: 87px;
  border: 3px solid #8B4900;
  border-radius: 48px;
  color: #52120A;
  font-size: 40px;
  font-weight: 400;
  line-height: 87px;
  letter-spacing: 24px;
  text-indent: 24px;
  text-align: center;
}

.celebrate-box {
  width: min-content;
  position: absolute;
  bottom: 130px;
}

.celebrate-left {
  left: calc(50vw - 350px);
}

.celebrate-center {
  right: calc(50vw - 150px);
}

.celebrate-right {
  right: calc(50vw - 350px);
}

.winner-list {
  width: 800px;
  min-height: 270px;
  display: flex;
  flex-flow: row wrap;
  overflow: scroll;
  margin: 16px auto 0px auto;
  padding: 10px 10px;
  border: 3px solid #FFE58D;
  border-radius: 10px;
}

.winner-list-title {
  width: 100%;
  color: #FFE58D;
  font-size: 40px;
  font-weight: 600;
  text-align: center;
}

.winner-list-box {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  overflow: scroll;
  margin: 0px 0px;
  padding: 0px 4px;
}

.winner-list-label {
  color: #FFE58D;
  font-size: 30px;
  font-weight: 600;
}

.winner-list-item {
  /* width: 120px;
  height: 40px;
  line-height: 40px; */
  padding: 2px 6px;
  text-align: center;
}

.winner-list-item-text {
  background-color: red;
  border-color: #FFE58D;
  color: #FFE58D;
  height: 45px;
  line-height: 40px;
  display: inline-block;
  padding: 0 10px;
  font-size: 30px;
  border-width: 3px;
  border-style: solid;
  border-radius: 8px;
  box-sizing: border-box;
  white-space: nowrap;
}

.setting-box {
  padding: 10px 20px;
  height: calc(100vh - 120px);
  min-height: 200px;
  overflow: scroll;
}

.setting-winner-list {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  overflow: scroll;
  margin: 8px 0px;
  padding: 0px 4px;
  border: 1px solid #FFE58D;
  border-radius: 10px;
}

.setting-winner-list-item{
  padding: 5px;
  text-align: center;
}

/* 定义滚动条样式 */
::-webkit-scrollbar {
  width: 0px;
  height: 0px;
  /* background-color: rgba(240, 240, 240, 1); */
}
</style>
