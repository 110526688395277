<template>
  <div>
    <div ref="bodyAnimation" :style="style"></div>
  </div>
</template>

<script>
import lottie from "lottie-web";
import celebrate1 from "@/assets/animations/celebrate-1.json";
import celebrate2 from "@/assets/animations/celebrate-2.json";

export default {
  name: "Celebrate",
  props: {
    options: {
      type: Object,
      required: true,
    },
    width: {
      type: Number,
      default: 300,
    },
    height: {
      type: Number,
      default: 300,
    },
    speed: {
      type: Number,
      default: 1,
    },
    control: {
      type: Boolean,
      default: false,
    }
  },

  data(){
      return{
        style: {
          width: this.width ? `${this.width}px` : "100%",
          height: this.height ? `${this.height}px` : "100%",
          overflow: "hidden",
          margin: "0 auto",
        },
        anim: null,
      }
  },

  watch: {
    control(value) {
      if (value) {
        this.anim.play();
      } else {
        this.anim.stop();
      }
    }
  },

  mounted() {
    this.anim = lottie.loadAnimation({
      container: this.$refs.bodyAnimation, // the dom element that will contain the animation
      renderer: "svg",
      loop: this.options.loop !== false,
      autoplay: this.options.autoplay !== false,
      animationData: this.options.type === 'default' ? celebrate1 : celebrate2,
    });
    this.anim.setSpeed(this.speed);
    this.$emit("animCreated", this.anim);
  },

  methods: {},
}
</script>
